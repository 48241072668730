import * as $ from 'jquery';
let lightbox = require('lightbox2');

export const init = () => {

    // change structure img in staticPage
    const staticPage = $('.lightboxAdd');
    const newsContent = $('.new-show-content');

    const staticPageVisible = staticPage.is(':visible');
    const newsContentVisible = newsContent.is(':visible');

    if( (staticPageVisible || newsContentVisible ) && (staticPage.find('img').length > 0 || newsContent.find('img').length > 0)){

        let container = staticPageVisible ? staticPage : newsContent;
        container.find('img').each((i: number, elem: any) => {
            if($(elem).parent().prop('tagName') != 'A' ) {
                let src = $(elem).attr('src');
                let elementClone = $(elem).clone(true) as any;
                let wrapper = `<a href="${src}" data-lightbox="image-${i}" data-title=""></a>` as any;
                $(wrapper).insertAfter($(elem));
                $(elem).next().html(elementClone);
                elem.remove();
            }
        })
    }

    lightbox.option({
        'resizeDuration': 200,
        'wrapAround': true,
        'albumLabel': "",
    });


    //show modal when side is opened first time
    setTimeout(()=>{
        if( $('.modal-global').hasClass('show-modal') ) $('.modal-global').find('a')[0].click();
    }, 100);

};