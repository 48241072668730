import * as $ from 'jquery';

export const init = () => {

    //change color after refresh or reload side
    let isChangedColor = localStorage.getItem('changeColor');
    if(isChangedColor == 'true'){
        changeColor();
        $('.view-option').addClass('clicked');
    }

    $('.view-option').on('click', (e:any) => {
        const self = $(e.currentTarget);
        self.toggleClass('clicked');
            if(self.hasClass('clicked')){
                changeColor();
                localStorage.setItem('changeColor', 'true');

            } else {
                $(':root').css({'--superColorBg2': '#FFFFFF','--superColorBg': '#FFFFFF', '--superColorText': '#222222', '--primaryColor': '#d2ab67', '--primaryColor2': '#d2ab67', '--primaryColorLight': '#efebe8', '--primaryColorLight2': '#efebe8', '--superGray': '#AEAEAE', '--superGrayBlack':'#EFEBE8', '--superGrayYellow':'#EFEBE8', '--superGrayLine':'#ADADAD', '--grayTextData': '#8E8E8E'});
                $('.logo-M, .logo-FK, .logo-footer-FK, .logo-Ultimate').removeClass('clicked');

                localStorage.setItem('changeColor', 'false');
            }
    })

    //change global language
    // $('.language-option__switch').on('click', (e:any)=>{
    //     const self = $(e.currentTarget);
    //
    //     $('.language-option__switch').removeClass('is-active');
    //     self.addClass('is-active');
    // });
};

export const changeColor = () => {
    $(':root').css({'--superColorBg2': '#FFFF00','--superColorBg': '#000', '--superColorText': '#FFFF00', '--primaryColor': '#FFFF00', '--primaryColor2': '#000', '--primaryColorLight': '#000', '--primaryColorLight2': '#FFFF00', '--superGray': '#FFFF00', '--superGrayBlack':'#000', '--superGrayYellow':'#FFFF00', '--superGrayLine':'#FFF', '--grayTextData':'#FFFF00'});
    $('.logo-M, .logo-FK, .logo-footer-FK, .logo-Ultimate').addClass('clicked');
};

// desc colors:
// --superColorBg: biały -> czarny
// --superColorText: czarny -> żółty
// --primaryColor: primary -> żółty
// --primaryColorLight: primaryLight -> czarny
// --primaryColorLight2: primaryLight -> żółty
// --superGray: #AEAEAE -> żółty
// --superGrayBlack: #EFEBE8 -> czarny
// --superGrayYellow: #EFEBE8 -> żółty
// --superGrayLine: #ADADAD -> żółty
// --grayTextData: #8E8E8E -> żółty



