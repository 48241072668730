import * as $ from 'jquery';
import {getContent} from "../getContent/getContent";
import * as getContentAll from "../getContent/getContent";
import * as loader from "../loader/animateLoader";

export const init = (container:string) => {

    console.log('init home pag')

    $('.pagination-btn').not('.pagination-arrow').on('click',async (e:any)=>{
        const self = $(e.currentTarget),
            link = self.attr('url-data-content'),
            containerLoader = $(`.${container}`),
            data = await getContent(link),
            paginationButton = $('.pagination-btn');

        loader.init(containerLoader);
        containerLoader.html(data);
        setTimeout( () => $("html, body").animate({scrollTop: 0}, "slow"),100);

        paginationButton.removeClass('is-active');
        $(self).addClass('is-active');

        //get content
        getContentAll.init();

        const maxPagination = paginationButton.not('.pagination-arrow').last().attr('number'),
            clickedPagination = $(self).attr('number'),
            rangeWithDots = pagination(Number(clickedPagination), Number(maxPagination));
        changeNumber(rangeWithDots)
    });

    $('.pagination-arrow').on('click', (e:any) => {
        const self = $(e.currentTarget),
            direction = self.data('direction'),
            step = self.data('step'),
            paginationButton = $('.pagination-btn');

        if(direction === 'right'){
            step === 'next' ? $('.pagination-btn.is-active').next().trigger('click')
                : paginationButton.not('.pagination-arrow').last().trigger('click');
        } else {
            step === 'prev' ? $('.pagination-btn.is-active').prev().trigger('click')
                : paginationButton.not('.pagination-arrow').first().trigger('click');
        }
    });
};

const pagination = (c:any, m:any) => {
    let current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        counter;

    for (let index = 1; index <= last; index++) {
        if (index == 1 || index == 2 || index == 3 || index == (last-1) || index == (last-2) || index == last || index >= left && index < right) {
            range.push(index);
        }
    }

    for (let elem of range) {
        if (counter) {
            if (elem - counter === 2) {
                rangeWithDots.push(counter + 1);
            } else if (elem - counter !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(elem);
        counter = elem;
    }
    return rangeWithDots;
};

const changeNumber = (array:any) => {
    $('.pagination-btn').not('.pagination-arrow').addClass('d-none');
    $('.pagination-dots').remove();

    $(array).each( (index:number, elem:any)=>{
        $(`.pagination-btn[number="${elem}"]`).removeClass('d-none');

        if(elem == '...'){
            $('<div class="pagination-dots">....</div>').insertAfter($(`.pagination-btn[number="${array[index-1]}"]`));
        }
    });
};