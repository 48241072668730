import * as $ from 'jquery';

export const init = () => {
    //show input with search
    $('#searchInput, #searchInput2').on('click', () => {
        const isPL = $('.header-rightSide .pl-option').hasClass('is-active');
        $('body').append(search(isPL)).css('overflow','hidden');
        $('.dynamic-content').addClass('blurScreen');
        //close search
        initClose();
        //send and send after enter
        sendEnter();
    });
    $('.menu-item').on('mouseover', (e) => {
        e.target.closest('.menu-item').classList.add('is-actived');
    })
    $('.menu-item').on('mouseleave', (e) => {
        setTimeout(function(){
            e.target.closest('.menu-item').classList.remove('is-actived');
        }, 300);
    })
    $('.dropdown-submenu').on('mouseover', (e) => {
        e.target.closest('.dropdown-submenu').classList.add('is-actived');
    })
    $('.dropdown-submenu').on('mouseleave', (e) => {
        setTimeout(function(){
            e.target.closest('.dropdown-submenu').classList.remove('is-actived');
        }, 300);
    })
};

const url = $('#searchInput').attr('url-ajax-data');

const closeIcon = `
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5845 7.5L15 13.9155L13.9147 15L7.5 8.5845L1.0845 15L0 13.9155L6.41475 7.5L0 1.0845L1.0845 0L7.5 6.4155L13.9147 0L15 1.0845L8.5845 7.5Z" fill="#fff"/>
</svg>
`;

const search = (isPL:boolean) => `<div class=" to-flex-ai search-blur">
        <div class="closeIcon">${closeIcon}</div>
        <div class="search-content">
            <div class="to-flex-cl">
                <input type="text" class="search-input" placeholder="${isPL ? 'Wpisz szukaną frazę' : 'What are you looking for?'}">
                <label class="xss-span error-label error-mail"></label>
            </div>
        </div>
        <div class="primary-btn-light" id="send-search" url-data="${url}">${isPL ? 'Szukaj' : 'Search'}</div>
        </div>`;

const initClose = () => {
    $('.closeIcon').on('click', ()=>{
        $('body').attr('style', '');
        $('.search-blur').remove();
        $('.dynamic-content').removeClass('blurScreen');
    });
};

const sendEnter = () => {

    $('#send-search').on('click', (e:any) => {
        let link = $(e.currentTarget).attr('url-data');
        const keyword = $('.search-input').val() as string;
        let valid = validKeyword(keyword);

        if(valid) {
            link = `${link}?keyword=${keyword}`
            window.location.href = link;
        }
    });

    $('.search-input').on('keypress' ,(e:any)=>{
        if (e.which == 13){
            $('#send-search')[0].click();
            return true;
        }
    });
};

const validKeyword = (keyword:string) => {
    if(keyword.length <= 2 ){
        $('.search-blur .error-label').text('Fraza musi zawierać co najmniej 3 znaki');
        return false;
    };
    return true;
};

