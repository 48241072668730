import * as $ from 'jquery';
import * as loader from "../loader/animateLoader";
import * as ajaxEvents from "../../ajaxEvents";
import * as getContent from "../getContent/getContent";
import * as Timerinit from "./timer";

export const init = () => {
    $('.reservationSend').on('click', (e:any)=>{
        const step = $(e.currentTarget).attr('step');
        let url = $(e.currentTarget).attr('ajax-data');
        let container = $('.main-container');
        let data:any;
        let isVerify = false;
        let fullReload = false;
        loader.init(container);


        switch (step) {
            case "1":
                $('.reservation-table').find('.table').children().each( (i:number, elem:any) => {
                    let id = $(elem).find('.remove-reservation').attr('id-space-table');
                    let ticket = $(elem).find('input[type="checkbox"]:checked').attr('value');
                    data = data == undefined ? `id-${i}=${id}&ticket-${i}=${ticket}` : `${data}&id-${i}=${id}&ticket-${i}=${ticket}`;
                });

                if( !$('.timer').hasClass('is-active') ) Timerinit.init();
                isVerify = true;
                break;
            case "2":
                data = $('form').serialize();
                isVerify = true;
                break;
            case "3":
                if(!$('#rules').is(':checked')){
                    $('.cbx').addClass('error');
                    $('.load-container').remove();
                } else {
                    isVerify = true;
                }

                break;
        }

        if(isVerify){
            $.ajax({
                type: "POST",
                url,
                data,
                success: (req) =>{
                    container.html(req.content);
                    setTimeout($("html, body").animate({ scrollTop: 0 }, "slow"), 1000);
                    // init event side
                    ajaxEvents.init();
                    getContent.init();
                },
            });
        }

    });
};