import * as $ from 'jquery';

let clonedId:number = 0;

export const init = () => {
    const isPL = $('.header-rightSide .pl-option').hasClass('is-active');

    // choose space in grid - main controller
    $('.reservation-room').find('.space').on('click', (e:any) => {
        const self = $(e.currentTarget);
        let numberSpace = '' as any;
        let idSpace = self.attr('id-space') as any;
        let chooseTicket = $('.choose-ticket');
        if(self.hasClass('free-space')){
            numberSpace = self.attr('space-number');
            self.toggleClass('free-space choose-space').html(numberSpace);
            let cloneElem = $('.prototypeTable').first().clone(true);
            cloneElem.removeClass('prototypeTable d-none');
            let countSpace = $('.reservation-room ').find('.choose-space').length;
            let rowSpace = self.parent().attr('row-number');

            cloneElem.find('.title-space').html(`${isPL ? 'Miejsce' : 'Place'} ${countSpace}:`);
            cloneElem.find('.rowNumberSpace').html(`${isPL ? 'Rząd' : 'Row'}: ${rowSpace}, ${isPL ? 'Miejsce' : 'Place'} ${numberSpace}`);
            cloneElem.find('.remove-reservation').attr('id-space-table',idSpace);
            changeId(cloneElem);
            cloneElem.appendTo($('.table'));
            chooseTicket.find('.ticket').append(`<li class="xs-span ticket-item to-flex-ai" choose-id="${idSpace}"><span>${isPL ? 'Rząd' : 'Row'}: ${rowSpace}, ${isPL ? 'Miejsce' : 'Place'} ${numberSpace}</span> ${closeIcon}</li>`);
            chooseTicket.removeClass('d-none');
            iconDelete(idSpace);

        } else if (self.hasClass('choose-space')){
            self.toggleClass('free-space choose-space').html(numberSpace);
            $(`[id-space-table=${idSpace}]`).closest('.table-item').remove();
            chooseTicketNumber(idSpace);
            changeNameSpace( $('.reservation-table').find('.table') );
            changeNameSpace( $('.choose-ticket').find('.ticket') );

        }

        changeBasket();
    });

    //remove item from list
    $('.remove-reservation').on('click', (e:any) => {
        const self = $(e.currentTarget);
        self.addClass('disabled');
        const id = self.attr('id-space-table');
        let countTickets:any;
        if(self.hasClass('remove-basket')){
            countTickets = self.closest('.table').children().length;
            self.parent().append(confirmRemove);
        }

        self.closest('.remove-section').append(confirmRemove);

        $('.removeItem').on('click', ()=>{
            self.closest('.table-item').remove();
            $(`.choose-space[id-space="${id}"]`).toggleClass('free-space choose-space').html('');

            if(!self.hasClass('remove-basket')){
                changeNameSpace( $('.choose-ticket').find('.ticket') );
                changeBasket();
                chooseTicketNumber(id);
            } else {
                $.get( self.attr('href'), (data)=> {
                    console.log(data)
                })
            }
            changeNameSpace( $('.reservation-table').find('.table') );

            $('.ticket-item svg').removeClass('is-active');

            if(countTickets == 1){
                $('.basket-seats .table').append(`<div class="reservationError">${isPL ? 'Nie wybrano biletu' : 'No ticket'}</div>`);
                $('.reservationSend').addClass('error-ticket');
            }
        });

        $('.closeItem').on('click', ()=>{
            $('.confirmRemove').remove();
            self.removeClass('disabled');
            $('.ticket-item svg').removeClass('is-active');
        });
    });

    // remove window with confirm delete
    $(document).on('click', (e: any) => {
        let $clicked = $(e.target);
        if (!$clicked.parents().hasClass("confirmRemove") ) {

            if ($clicked.hasClass('remove-reservation') || $clicked.closest('.ticket').length > 0) {

            } else {
                $('.closeItem').trigger('click');
                $('.ticket-item svg').removeClass('is-active');
            }
        }
    });

    // local settings
    if( $('.reservation-room').find('.choose-space').length > 0 ){
        let elements = $('.reservation-room').find('.choose-space');
        elements.each( ( i:number, elem:any )=>{
            let id = $(elem).attr('id-space');
            let ticketType = $(elem).attr('ticket');
            $(`[id-space="${id}"]`).toggleClass('choose-space free-space').trigger('click');
            let tableInput = $(`[id-space-table="${id}"]`).closest('.table-item').find(`input[value="${ticketType}"]`);

            if(!$(tableInput).parent().hasClass('is-active')){
                $(tableInput).parent().click();
            }
        });
    }

};


export const changeBasket = () => {
    let ticketCount = $('.reservation-room ').find('.choose-space').length;
    let ticketCost = 0;
    const ticketCountBox = $('.ticket-count');
    const isPL = $('.header-rightSide .pl-option').hasClass('is-active');

    $('.reservation-table').find('input:checked').each( (i:number, elem:any)=>{
        let costElem = $(elem).attr('cost');
        ticketCost += Number(costElem);
    });

    if( ticketCountBox.length > 0 ){
        if(isPL) {
            ticketCountBox.html(`${ticketCount == 0 ? '' : ticketCount} ${ticketCount == 1 ? 'bilet' : ticketCount == 0 ? 'nie wybrano' : 'bilety'}`);
        } else {
            ticketCountBox.html(`${ticketCount == 0 ? '' : ticketCount} ${ticketCount == 1 ? 'ticket' : ticketCount == 0 ? 'no ticket' : 'tickets'}`);
        }
        $('.ticket-cost').html(`${ticketCost} zł`);

        let nextBtn = $('.reservation-buttons').find('.primary-btn');
        ticketCount > 0 ? nextBtn.removeClass('disabled') : nextBtn.addClass('disabled');
    }
};

const changeNameSpace = (block:JQuery) => {
    const isPL = $('.header-rightSide .pl-option').hasClass('is-active');
    let name = isPL ? 'Miejsce' : 'Place';
    $(block).children().each( (i:number, elem:any)=>{
        $(elem).find('.title-space').html(`${name} ${i+1}:`)
    });
};

const chooseTicketNumber = (id:string) => {
    let chooseTicket = $('.choose-ticket');
    chooseTicket.find(`[choose-id="${id}"]`).remove();

    if( chooseTicket.find('.ticket').children().length ==  0 ){
        chooseTicket.addClass('d-none');
    }
};

const iconDelete = (id:any) => {

    $(`[choose-id=${id}] svg`).on('click', (e:any) => {
        const self = $(e.currentTarget);
        const id = $(self).parent().attr('choose-id');
        self.addClass('is-active');

        self.closest('.ticket-item').append(confirmRemove);

        $('.removeItem').on('click', ()=>{
            $(`[id-space="${id}"]`).trigger('click');
            $('.ticket-item svg').removeClass('is-active');
        });

        $('.closeItem').on('click', ()=>{
            $('.confirmRemove').remove();
            $('.ticket-item svg').removeClass('is-active');
        });
    });
};

const closeIcon = `<svg width="14" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5845 7.5L15 13.9155L13.9147 15L7.5 8.5845L1.0845 15L0 13.9155L6.41475 7.5L0 1.0845L1.0845 0L7.5 6.4155L13.9147 0L15 1.0845L8.5845 7.5Z" fill="#FF2727"/>
</svg>
`;

const changeId = (cloneElem:any) => {

    cloneElem.find('input[type="checkbox"]').each( (i:number, elem:any)=>{
        $(elem).attr('id', `${$(elem).attr('id')}-${clonedId}`);
        $(elem).next().attr('for', `${$(elem).next().attr('for')}-${clonedId}`);
    });

    clonedId++;
};

const confirmRemove = `
<div class="confirmRemove">
    <div class="confirmRemove--title">Czy na pewno chcesz usunąć?</div>
    <div class="confirmRemove--buttons to-flex-ai">
    <button class="primary-btn-light closeItem">Anuluj</button>
    <button class="primary-btn removeItem">Usuń</button>
</div>
</div>`;