import * as $ from 'jquery';
import {getContent} from '../getContent/getContent';
import * as getAll from '../getContent/getContent';
import * as loader from '../loader/animateLoader';

export const init = () => {
    $('.pagination-side').on('click',async (e:any)=>{
        const self = $(e.currentTarget);
        const url = self.attr('url-data-ajax');
        const sideUp = self.attr('goto');

        let currentSide = $('.current-side').text() as any;
        currentSide = sideUp == 'next' ? Number(currentSide) + 1 : Number(currentSide) - 1;
        let newUrl = url.concat('?loader=t').replace('_PAGE_',currentSide);
        const container = $('.news-block');

        loader.init(container);

        let data = await getContent(newUrl) as any;
        container.html(data.content);

        //pagination - show arrow
        const lastSide = $('.last-side').text();


        const prevArrow = $('[goto="prev"] span');
        const nextArrow = $('[goto="next"] span');

        prevArrow.removeClass('d-none');
        nextArrow.removeClass('d-none');

        if(currentSide == lastSide) nextArrow.addClass('d-none');
        else if(currentSide == 1 ) prevArrow.addClass('d-none');

        $('.current-side').text(currentSide);
        getAll.init();
    })
};

