import * as $ from 'jquery';
import {getContent} from "../getContent/getContent";
import * as getContentAll from "../getContent/getContent";
import * as loader from "../loader/animateLoader";
import * as pagination from "../pagination/pagination";
import {changeBasket} from "../reservation/reservation";

export const init = () => {

    $(".select-item").on('click', (e: any) => {
        e.preventDefault();
        const self = $(e.currentTarget);
        $(".drop-down ul").not(self.closest('.drop-down').find('ul')).hide();
        $(".drop-down .arrow").removeClass('rotate_180');
        $(self).find(".arrow").toggleClass("rotate_180");
        self.closest('.drop-down').find('ul').toggle();
    });

    $(".options-item").on('click', (e: any) => {
        e.preventDefault();
        const self = $(e.currentTarget);
        let text = $(self).find('label').html();
        let dropDown = $(self).closest('.drop-down');
        dropDown.find("li").find('input').prop('checked', false);

        if(self.hasClass('is-active')){
            self.removeClass('is-active');
            text = dropDown.find('.filter-val').attr('placeholder');
            $('.reservationSend').addClass('disabled');
        } else {
            $('.reservationSend').removeClass('disabled');
            $(self).find('input').prop('checked', true);
            dropDown.find("li").removeClass("is-active");
            self.addClass('is-active');
            let url = dropDown.find('.options').attr('href');
            if(url != undefined){

                url = url.concat(self.find('input').attr('value'))
                $.get( url , (data)=>{
                    console.log(data);
                })
            }

        }

        dropDown.find('.filter-val').html(text);
        dropDown.find(".arrow").toggleClass("rotate_180");
        $(".drop-down ul").hide();
        changeBasket();
    });

    $(document).on('click', (e: any) => {
        let $clicked = $(e.target);
        if (!$clicked.parents().hasClass("drop-down")) {
            $(".drop-down ul").hide();
            $(".drop-down .arrow ").removeClass("rotate_180");
        }
    });

    // action after click filter
    $('.filter-btn').on('click', async (e:any) => {
        let get = '';
        let form = $('.repHead');
        get = form.find('input[name!="lightpickDate"]').serialize();
        let url = form.attr('action');

        let date = $('#filterMonth').val() as string;
        if( date != ''){
            let dateFrom = date.slice(0,10);
            let dateTo = date.slice(-10);
            get = get.concat(`&fDateFrom=${dateFrom}&fDateTo=${dateTo}`)
        }

        url = `${url}&${get}`;
        $.get( url , ()=> {
            url = url.replace('type=pagination&','');
            window.location.href = url;
        })

    });

    // set active filter when is checked
    let allFilters = $('.repHead input:checked');
    allFilters.each( (i:number, elem:any) => {
        elem.click();
    })
};

