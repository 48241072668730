import * as $ from 'jquery';

export const init = () => {

    $('.arrowController').on('click', (e:any) => {
        const self = $(e.currentTarget);
        const arrowSingle = self.attr('arrowSingle');
        const arrowSide = self.attr('arrowSide');

        const pagination = $('.swiper-pagination');
        const paginationActive = $('.swiper-pagination-bullet-active');

        if(arrowSingle == 'true'){
            if(arrowSide == 'right') paginationActive.next().trigger('click');
            else paginationActive.prev().trigger('click');
        } else {
            let child = arrowSide == 'right' ? 'last-child' : 'first-child';
            pagination.find(`span:${child}`).trigger('click');
        }
    });

    $('.swiper-slide').on('click', (e:any)=> {
        const self = $(e.currentTarget);
        $('.swiper-slide').removeClass('is-active');
        self.addClass('is-active');
    });

    hideArrowPagination();
    $(window).on('resize', () => hideArrowPagination() );

};

const hideArrowPagination = () => {
    const pagination = $('.swiper-pagination').children().length;
    const arrows = $('.arrow-right, .arrow-left');
    pagination == 1 ? arrows.hide() : arrows.show();
};