import * as $ from "jquery";

const flatpickr = require("flatpickr");
import { Polish } from "flatpickr/dist/l10n/pl.js";
import { getContent } from "../getContent/getContent";
import * as getContentAll from "../getContent/getContent";

export const init = () => {
  let currentMonth = 0,
    currentYear = 2023,
    monthMapPL = [
      "STYCZEŃ",
      "LUTY",
      "MARZEC",
      "KWIECIEŃ",
      "MAJ",
      "CZERWIEC",
      "LIPIEC",
      "SIERPIEŃ",
      "WRZESIEŃ",
      "PAŹDZIERNIK",
      "LISTOPAD",
      "GRUDZIEŃ",
    ],
    monthMapEN = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ],
    monthDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const $dayList = $(".calendar__days");

  const addDayElement = (date: any, $container: any) => {
    let isCurrentMonth: boolean;
    isCurrentMonth = date.getMonth() !== currentMonth;
    $container.append(
      `<div class="date ${
        isCurrentMonth ? "out-of-scope" : ""
      }"><span class="to-flex-center">${date.getDate()}</span></div>`
    );
  };

  const firstLastDates = (date: any) => {
    let startDate, endDate;
    startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    while (startDate.getDay() !== 1) {
      startDate.setDate(startDate.getDate() - 1);
    }
    endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    while (endDate.getDay() !== 0) {
      endDate.setDate(endDate.getDate() + 1);
    }
    return [startDate, endDate];
  };

  const renderDays = (dateRange: any) => {
    $dayList.empty();
    let startDate = dateRange[0],
      endDate = dateRange[1],
      currentDate = startDate;

    while (currentDate <= endDate) {
      addDayElement(currentDate, $dayList);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  };

  const loadCalendar = (date: any) => {
    currentMonth = date.getMonth();
    const isPL = $(".header-rightSide .pl-option").hasClass("is-active");
    $(".calendar__header--month").text(
      isPL ? monthMapPL[currentMonth] : monthMapEN[currentMonth]
    );
    if (!isPL) {
      let caledarDays = $(".calendar__week .calendar__week--day") as any;
      caledarDays.each((i: number, elem: any) => {
        $(elem).html(monthDays[i]);
      });
    }
    $(".calendar__header--year").text(date.getFullYear());
    renderDays(firstLastDates(date));
    let monthToAttr = currentMonth + 1;
    $("#datepicker").attr(
      "date",
      `${date.getFullYear()}-${
        monthToAttr < 10 ? "0" + monthToAttr : monthToAttr
      }`
    );
  };

  loadCalendar(new Date());

  let newCurMonthG = (currentMonth + 1).toString();
  if (newCurMonthG.length === 1) {
    newCurMonthG = `0${currentMonth + 1}`;
  }
  console.log(
    `?keyword=&fDateFrom=${currentYear}-${newCurMonthG}-01&fDateTo=${currentYear}-${newCurMonthG}-31`
  );
  let newEventsCalG = `?keyword=&fDateFrom=${currentYear}-${newCurMonthG}-01&fDateTo=${currentYear}-${newCurMonthG}-31`;
  let currentHrefG = $("#showAllCal").attr("data-href");
  $("#showAllCal").attr("href", `${currentHrefG}${newEventsCalG}`);

  $($dayList).on("click", ".is-event span", async (e: any) => {
    const self = $(e.currentTarget);
    $(".calendar-tooltip").remove();
    let url = $("#datepicker").attr("url-day-events");
    let daySome = self.text();
    if (daySome.length === 1) {
      daySome = `0${daySome}`;
    }
    let day = `${$("#datepicker").attr("date")}-${daySome}`;
    url = url.replace("_day_", day);
    let content = (await getContent(url)) as any;
    self.parent().append(content.content);
    //get content
    getContentAll.init();

    $(document).on("click", (e: any) => {
      const self = $(e.target);

      if (
        self.closest(".calendar-tooltip").length > 0 ||
        self.hasClass("date") ||
        length > 0
      ) {
      } else {
        $(".calendar-tooltip").remove();
      }
    });

    // Remove calendar tooltip on mobile
    $(".calendar-tooltip__close svg").on("click", function () {
      $(".calendar-tooltip").remove();
    });
  });

  $(".calendar__header--arrow").on("click", (e: any) => {
    const self = $(e.currentTarget);
    if (self.hasClass("arrow-left")) {
      currentMonth--;
      if (currentMonth < 0) {
        currentMonth = 11;
        currentYear--;
      }
    } else {
      currentMonth++;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear++;
      }
    }
    let newCurMonth = (currentMonth + 1).toString();
    if (newCurMonth.length === 1) {
      newCurMonth = `0${currentMonth + 1}`;
    }
    console.log(`?keyword=&fDateFrom=2021-08-01&fDateTo=2021-08-31`);
    console.log(
      `?keyword=&fDateFrom=${currentYear}-${newCurMonth}-01&fDateTo=${currentYear}-${newCurMonth}-31`
    );
    let newEventsCal = `?keyword=&fDateFrom=${currentYear}-${newCurMonth}-01&fDateTo=${currentYear}-${newCurMonth}-31`;
    let currentHref = $("#showAllCal").attr("data-href");
    $("#showAllCal").attr("href", `${currentHref}${newEventsCal}`);

    loadCalendar(new Date(currentYear, currentMonth));
    setTimeout(takeEventDate, 200);
  });

  setTimeout(takeEventDate, 200);

  if ($("#filterMonth").length > 0) {
    let fp = flatpickr("#filterMonth", {
      locale: Polish,
      mode: "range",
      minDate: "2019-09",
    });

    if ($("#filterMonth").val() != "") {
      let date = $("#filterMonth").val() as string;
      let dateFrom = date.slice(0, 10);
      let dateTo = date.slice(-10);
      fp.setDate([dateFrom, dateTo], true);
    }
  }
};

const takeEventDate = async () => {
  if ($("#datepicker").is(":visible")) {
    let url = $("#datepicker").attr("url-events");
    url = url.replace("_month_", $("#datepicker").attr("date"));
    let data = (await getContent(url)) as any;
    let allDay = $(".date:not(.out-of-scope)");
    data.map((elemData: any) => {
      allDay.each((index: any, elem: any) => {
        let textDay = $(elem).text();
        if (textDay.length === 1) {
          textDay = `0${textDay}`;
        }
        if (textDay == elemData) {
          $(elem).addClass("is-event");
          return false;
        }
      });
    });
  }
};
