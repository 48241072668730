import * as $ from 'jquery';

export const init = () => {
    const isPL = $('.header-rightSide .pl-option').hasClass('is-active');
    // check local cache
    if( $('.reservationPage--form').is(':visible') ){
        let blockButton = false;
        $('.required').each( (i:number, elem:any) => {
            if ($(elem).val() == '') blockButton = true;
        });
        if(blockButton == false) $('.reservationSend').removeClass('disabled');
    }

    $('.only-number').on('keydown', (e:any)=>{
        let parent = $(e.currentTarget).parent();
        let spaceAccept = $(e.currentTarget).attr('id') == 'form-telephone';
        let crossAccept = $(e.currentTarget).attr('id') == 'form-postCode';

        //custom action
        if( (e.key == ' ' && spaceAccept) || (e.key == 'Tab') || ((e.key == '+' || e.key == 'Shift') && spaceAccept ) || (e.key == '-' && crossAccept) ) return true;
        //backspace
        else if( e.key == 'Backspace') {
            setTimeout(()=>{
                if($(e.currentTarget).val() == '') $(parent).find('.error-label').remove();
                },100);
            return true;
        }
        //only number
        else if( !(/^[0-9]*$/.test(e.key)) ) {
            e.preventDefault();
            if($(parent).find('.error-label').length == 0) $(parent).append(`<label for="" class="error-label">${isPL ? 'Pole może zawierać jedynie cyfry' : 'The field can only contain numbers'}</label>`);
        } else {
            // NIP with only number
            $(e.currentTarget).attr('id') == 'form-NIP' ? setTimeout( checkNIP(e),100)
                : $(parent).find('.error-label').remove();
        }
    });

    $('#form-NIP').on('change', (e:any) => {
        setTimeout( ()=> {
            checkNIP(e)
        }, 100);
    });

    $('#form-email').on('change', (e:any)=>{
        let emailVal = $(e.currentTarget);
        let parent = emailVal.parent();

        setTimeout( ()=>{
            let emailValid = isEmail( $(emailVal).val() as string );
            if(emailValid) {
                $(parent).find('.error-label').remove()
            } else {
                if($(parent).find('.error-label').length == 0) $(parent).append(`<label for="" class="error-label error-important">${isPL ? 'Błędny adres email' : 'Invalid email address'}</label>`);
            }
        }, 100)

    });

    $('.required').on('keydown', () => changeForm(false));

    $('#addFV').on('change', ()=>{
        if($('#addFV').is(':checked')){
            $('.sendForm').addClass('disabled');
            $('.add-form').slideDown();
            $('.FV').on('keydown', () => changeForm(true));

        } else {
            $('.add-form').slideUp();
            changeForm(false)
        }

    });
};

const changeForm = (fv:boolean) => {
    let isValid = true;
    let items = fv ? '.FV' : '.required';

    if ($('#form-email').val() != '' ) $('#form-email').trigger('change');

    if(fv) if ($('#form-NIP').val() != '' ) $('#form-NIP').trigger('change');

    setTimeout( () => {
        $(items).each( (i:number, elem:any) => {
            if($(elem).val() === '' || $(elem).parent().find('.error-important').length > 0) {
                isValid = false;
            }
        });

        let sendForm = $('.sendForm');
        isValid ? sendForm.removeClass('disabled') : sendForm.addClass('disabled');
    }, 100);

};

export const isEmail = (email:string) => {
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

const checkNIP = (e:any) => {
    let value = $(e.currentTarget).val() as string;
    let parent = $(e.currentTarget).parent();
    const isPL = $('.header-rightSide .pl-option').hasClass('is-active');

    if( !(/^.{10}$/.test(value)) ) {
        if($(parent).find('.error-label').length == 0) $(parent).append(`<label for="" class="error-label error-important">${isPL ? 'NIP musi zawierac 10 cyfr' : 'NIP number must contain 10 digits'}</label>`);
    } else {
        $(parent).find('.error-label').remove();
    }
}