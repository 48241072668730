import * as $ from 'jquery';

export const init = () => {
    let dateNow: any = (+new Date);
    localStorage.setItem('time', dateNow);

    const second = 1000,
        minute = second * 60,
        hour = minute * 60;

    let milisek = 900000;
    let newTime: any = (+localStorage.getItem('time'));
    let time: any = newTime + milisek;
    time -= (+new Date);
    let countDown = 1000,
        x = setInterval(function () {
            if (newTime != (+localStorage.getItem('time'))) {
                time = (+localStorage.getItem('time')) + milisek;
                time -= (+new Date);
            }
            let distance = time - countDown;
            time = distance;

            if (time < 1000) {
                $('.logo-FK')[0].click();
            }

            $('.timer__time').html(`${twoDigits(Math.floor((distance % (hour)) / (minute)))}:${twoDigits(Math.floor((distance % (minute)) / second))}`);

        }, second) as any;
    localStorage.setItem('timeInterval', x);
    setTimeout(()=>{
        $('.timer').addClass('is-active')
    }, 1000)
};

const twoDigits = (n: any) => {
    return (n <= 9 ? "0" + n : n);
};







