import * as $ from 'jquery';

export const init = () => {

    let zoom = 1, zoomPlus = 0, zoomMinus = 0;
    let windowWidth = $(window).width();
    if( windowWidth <= 576) {
        $('.zoom-minus').addClass('disabled');
        zoom = 0.5;
        zoomPlus = 0;
        zoomMinus = 0;
    } else if(windowWidth <= 768) {
        zoom = 0.7
    }
    $('.zoom-plus').on('click', function(){
        zoom += 0.1;
        zoomPlus += 1;
        zoomMinus -= 1;
        if( windowWidth <= 576) {
            zoom += 0.3;
            $('.zoom-minus').removeClass('disabled');
            if(zoomPlus <= 2){
                $('.reservation-room').css('zoom', zoom);
                $('.reservation-wrapper').css('overflow', 'auto');
                if(zoomPlus == 2){
                    $('.zoom-plus').addClass('disabled');
                }
            }
        } else  {
            if(zoomPlus <= 2){
                $('.reservation-room').css('zoom', zoom);
                $('.reservation-wrapper').css('overflow', 'auto');

                if(zoomPlus == 2){
                    $('.zoom-plus').addClass('disabled');
                }
            }
        }
        $('.zoom-minus').removeClass('disabled');

    });

    $('.zoom-reset').on('click', function(){
        zoom = 1;
        zoomPlus = 0;
        zoomMinus = 0;
        if(windowWidth <= 576) {
            zoom = 0.5;
            console.log(1)
            $('.reservation-wrapper').css('overflow', 'visible');
        } else if(windowWidth <= 768) {
            zoom = 0.7;
            console.log(2)

        }

        $('.reservation-wrapper').css('overflow', 'visible');
        $('.zoom-plus').removeClass('disabled');
        $('.zoom-minus').addClass('disabled');

        $('.reservation-room').css('zoom', zoom);
    });

    $('.zoom-minus').on('click', function(){
        zoom -= 0.1;
        zoomMinus += 1;
        zoomPlus -= 1;
        // $('.zoom-minus').removeClass('disabled');

        if( windowWidth <= 576) {
            zoom -= 0.3;
            if(Number(zoom.toFixed(2))>= 0.5){
                $('.reservation-room').css('zoom', zoom);
                $('.reservation-wrapper').css('overflow', 'auto');

                if(Number(zoom.toFixed(2)) == 0.5){
                    $('.zoom-minus').addClass('disabled');
                }
            }
        } else  {
            if(zoomMinus <= 2){
                $('.reservation-room').css('zoom', zoom);
                $('.reservation-wrapper').css('overflow', 'auto');

                if(zoomMinus == 2){
                    $('.zoom-minus').addClass('disabled');
                }
            }
        }
        $('.zoom-plus').removeClass('disabled');
    });

    const slider = document.querySelector('.reservation-wrapper') as HTMLElement;
    let isDown = false;
    let startX:any;
    let scrollLeft:any;

    if(slider != undefined) {

        slider.addEventListener('mousedown', (e: any) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e: any) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });

    }

};