import * as $ from 'jquery';

//always init----------------------------------------------
import * as search from './components/home/search';
import * as getContent from './components/getContent/getContent';
import * as lightbox from './components/lightbox/lightbox';

import * as globalColor from './components/home/globalChangeColor';

import * as hamburgerMenu from './components/menu/hamburger-menu';
import * as leftMenu from './components/menu/left-menu';

import * as changeFontsize from './components/change-fontsize/change-fontsize';

import * as navMobile from './components/nav-mobile/nav-mobile';
import * as dropMenu from './components/nav-mobile/drop-menu';

import * as ajaxEvents from './ajaxEvents';

import * as arrowUp from './components/home/upToArrow';

import * as themes from './components/themes/themes';
//------------------
//always init
//------------------




//search main
search.init();
// //get content
getContent.init();
//global change color
globalColor.init();
//menu
hamburgerMenu.init();
//change-fontsize
changeFontsize.init();
//nav-mobile
$(window).width() <= 992 ? navMobile.init() : '';
dropMenu.init();
//global up arrow
arrowUp.init();
// init sidd
ajaxEvents.init();

// reload content after click back btn browser
$(window).on('popstate', function() {
    location.reload(true);
});
