import * as $ from 'jquery';

export const init = () => {
    $('.building-switcher--button').on('click', (e:any)=> {
        const self = $(e.currentTarget);
        const containerShow = self.attr('show-content');
        self.siblings().removeClass('is-active');
        self.addClass('is-active');

        $('.container-picture>div').addClass('d-none');
        $('.container-picture').find(`.${containerShow}`).removeClass('d-none');
    });
};