import * as $ from 'jquery';

export const init = () => {

    $('.pay-item').on('click', (e:any) => {
       const self = $(e.currentTarget);
       self.siblings().removeClass('is-active');
       self.addClass('is-active');
       let currentPay = self.attr('data-type');
       $('.activePay').attr('value', currentPay);
       $('.reservation-buttons .primary-btn').removeClass('disabled');
    });

};