import * as $ from 'jquery';

let Swiper = require('swiper');

export const init = () => {
    let mySwiperSponsorTwo = new Swiper ('.sponsorTwo .swiper-container', {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,
        speed: 800,
        // centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });

    let mySwiperSponsorOne = new Swiper ('.sponsorOne .swiper-container', {
        spaceBetween: 20,
        loop: true,
        speed: 800,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });

    let gallerySwiper = new Swiper ('.gallery-section .swiper-container', {
        spaceBetween: 20,
        slidesPerView: 2,
        freeMode: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 480px
            460: {
                slidesPerView: 3,
            },
            // when window width is >= 640px
            776: {
                slidesPerView: 4,
                // spaceBetween: 40
            },
            993: {
                slidesPerView: 7,
                // spaceBetween: 20
            },
            1212: {
                slidesPerView: 10,
            }
        }
    });

    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        thumbs: {
            swiper: galleryThumbs
        }
    });
    var galleryBanner = new Swiper('.gallery-banner', {
        spaceBetween: 10,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        effect: "fade",
    });

    var swiper = new Swiper('.staticPages .swiper-container', {
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}