import * as $ from 'jquery';

export const init = () => {
    $('span.size').on('click', function () {
        console.log(123);
        var size = $(this).data('size');
        $('html').css({
            fontSize: parseFloat(size) + '%'
        });
        $('span.size').removeClass('is-active');
        $(this).addClass('is-active');
    });
};