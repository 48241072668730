import * as $ from 'jquery';

export const init = () => {
    // RESPONSIVE MENU NAVIGATION
    $('.arrowSub').on('click', function(e:any) {
        e.preventDefault();
        $(this).next('ul').toggleClass('open-sub');
    });

    $('.arrowGlobal').on('click', function(e:any) {
        e.preventDefault();
        $(this).next('ul').toggleClass('open-sub');
    });

    // back buttons responsive menu
    $('.back1').on('click', function (e:any) {
        e.preventDefault();
        $('.menu-item ul').removeClass('open-sub');
    });

    $('.back2').on('click', function (e:any) {
        e.preventDefault();
        $('.dropdown-submenu ul').removeClass('open-sub');
    });
};