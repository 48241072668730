import * as $ from "jquery";

import * as swiper from "./components/swiper/swiper";
import * as changeNews from "./components/home/changeNews";
import * as newsletter from "./components/home/newsletter";

import * as pagination from "./components/pagination/pagination";
import * as arrowController from "./components/swiper/arrowController";

import * as buildingSwitcher from "./components/building/buildingSwitcher";

import * as reservation from "./components/reservation/reservation";
import * as reservationForm from "./components/reservation/form";
import * as reservationBasket from "./components/reservation/basket";
import * as reservationPay from "./components/reservation/pay";
import * as reservationSendData from "./components/reservation/sendData";
import * as reservationZoom from "./components/reservation/zoom";

import * as filter from "./components/filter/filter";
import * as calendar from "./components/lightpick/lightpick";
import * as getContent from "./components/getContent/getContent";

import * as loginPlan from './components/login';
import * as lightbox from "./components/lightbox/lightbox";
import * as leftMenu from "./components/menu/left-menu";
import * as themes from "./components/themes/themes";

import * as dropMenu from './components/nav-mobile/drop-menu';

export const init = () => {

    const containerType = $('[container-type]').attr('container-type');
    // init after ajax
    //calendar main
    calendar.init();
    //carousel
    swiper.init();
    //show photo
    lightbox.init();
    leftMenu.init();
    // staticPage
    themes.init();
    dropMenu.init();

    // delete interval
    if (!(containerType == 'reservation')){
        let intervalId = localStorage.getItem('timeInterval') as any;
        clearInterval(intervalId);
    }

    //------------------
    // home page
    //-------------------
    if (containerType == 'home') {
        //pagination news
        changeNews.init();
        //send newsletter
        newsletter.init();
        pagination.init('news-block');

    //-------------------
    // search page
    //-------------------
    } else if (containerType == 'search') {
        //pagination primary
        pagination.init('search-section-content');

        //-------------------
        // news list
        //-------------------
    } else if (containerType == 'building') {
        //building switcher
        buildingSwitcher.init();

    //-------------------
    // news list
    //-------------------
    } else if (containerType == 'news-list') {
        //pagination primary
        pagination.init('news-list');


    //------------------
    // gallery
    //-------------------
    } else if (containerType == 'gallery') {
        //arrow controller
        arrowController.init();

    //------------------
    // movies
    //-------------------
    } else if (containerType == 'movies') {
        //pagination primary
        pagination.init('building-video');

    //------------------
    // reservation
    //-------------------
    } else if (containerType == 'reservation') {
        filter.init();
        reservation.init();
        reservationForm.init();
        reservationBasket.init();
        reservationSendData.init();
        reservationPay.init();
        reservationZoom.init();
    //------------------
    // repertoire
    //-------------------
    } else if (containerType == 'repertoire') {
        pagination.init('repContent');
        //filer repertoire
        filter.init();

    //------------------
    // attemptPlan
    //-------------------
    } else if (containerType == 'attemptPlan') {
        pagination.init('attemptPlan');

    //------------------
    // cycles
    //-------------------
    } else if (containerType == 'cycles') {
        pagination.init('repContent');
        //filer repertoire
        filter.init();

    //------------------
    // login rehearse plan
    //-------------------
    } else if (containerType == 'login-plan') {
        loginPlan.init()
    }
};
