import * as $ from 'jquery';
import * as loader from "../loader/animateLoader";
import * as ajaxEvents from "../../ajaxEvents";

export const init = ( block:JQuery = $('.get-content') ) => {

    // const block = $('.get-content');
    block.on('click',async (e:any)=>{
        e.preventDefault();
        const self = $(e.currentTarget);
        let url = self.attr('url-data-ajax') as any;
        let currentMenu:string;
        if(self.closest('.left-container--menu ').length > 0){
            currentMenu = self.text();
        }
        if(url != undefined) {
            let manageContainer = self.attr('change-container');
            const container = manageContainer != undefined ? $(`.${manageContainer}`) : $('.main-container');
            loader.init(container);
            let get = url.includes("?") ? '&' : '?';
            let newUrl = url.concat(`${get}loader=t`);
            let data = await getContent(newUrl) as any;
            let historyUrl = url.includes("?");
            if(historyUrl){
                historyUrl = url.replace(/[^?]+$/g, '');
                historyUrl = historyUrl.slice(0,-1);
            } else {
                historyUrl = url;
            }
            history.pushState(historyUrl, 'Title', historyUrl);
            container.html(data.content);
            setTimeout($("html, body").animate({ scrollTop: 0 }, "slow"), 1000);
            init(container.find('.get-content'));
            // init sidd
            ajaxEvents.init();

            if(self.closest('.left-container--menu ').length > 0){
                $('.left-container--menu a').each( (i:number, elem:any) => {
                    if($(elem).text() == currentMenu ){
                       $(elem).addClass('is-active');
                        $(elem).closest('.left-menu__item').removeClass('d-none');
                        $(elem).closest('li.left-menu__item').find('.item__arrow').addClass('is-active');
                   }
                });
            }
        }

    });
};

export async function getContent(url:string){
    let content;
    await $.get(url, (data)=>{
        content = data;
    });
    return content;
}