import * as $ from 'jquery';
import {isEmail} from "./reservation/form";

export const init = () => {
    console.log('login');

    //override default html5 validation
    $( "#login-name, #login-password" ).on( "invalid", ( e:any ) => {
            e.preventDefault();
            let parent = $(e.currentTarget).parent();
            if($(parent).find('.error-label').length == 0) $(parent).append('<label for="" class="error-label">Pole wymagane</label>');
        });
};