import * as $ from "jquery";

export const init = () => {
  const staticPage = $(".staticPages") as any;
  if ($(".page-175, .page-176, .page-177").is(":visible")) {
    $("table").hide();
    let text = $(".header-rightSide .pl-option").hasClass("is-active")
      ? "Skład zespołu"
      : "Band composition";
    $(
      `<button class="primary-btn show-details">${text}</button><br>`
    ).insertBefore($("table"));
  }

  $(".show-details").on("click", () => $(".table").toggle());
  //   <a classname="defaultHide" href="mailto:kasa@filharmonia.krakow.pl" target="_blank">
  $(".btn-show-email").on("click", (e) => showEmail(e));

  const showEmail = (e: any) => {
    const emailDomain = "@filharmonia.krakow.pl";
    const email = e.target.parentElement.querySelector(".defaultShow");
    const textEmail = email.innerHTML.split("@");
    const finalEmail = textEmail[0] + emailDomain;
    const linkEmail = document.createElement("a");
    linkEmail.href = `mailto:${finalEmail}`;
    linkEmail.setAttribute("target", "_blank");
    linkEmail.innerHTML = finalEmail;
    e.target.parentElement.appendChild(linkEmail);
    e.target.style.display = "none";
    email.style.display = "none";
  };
};
