import * as $ from 'jquery';

export const init = () => {
    // RESPONSIVE MENU NAVIGATION IN MAIN SECTION
    $('.drop-menu-show').on('click', function(){
       $('.drop-menu__content').addClass('show');
       $('.main-container--change-size .container').addClass('change-size');
       $('.drop-menu-close').on('click', function () {
           $('.drop-menu__content').removeClass('show');
           $('.main-container--change-size .container').removeClass('change-size')
       })
    });
};