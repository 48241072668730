import * as $ from 'jquery';

export const init = () => {
    $('.upTo-arrow').on('click', () => {
        $("html, body").animate({ scrollTop: 0 }, "slow")
    });

    $(document).on('scroll', () => {
        if( $(window).scrollTop() > 150 ){
            $('.upTo-arrow').css('display','flex');
        } else {
            $('.upTo-arrow').css('display','none');
        }
    });
};