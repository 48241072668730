import * as $ from "jquery";

export const init = () => {
  $(".newsletter-btn").on("click", (e: any) => {
    const self = $(e.currentTarget);
    const url = self.attr("url-newsletter");
    const email = String($(".newsletter-input").val()).replace(/\s+/g, "");

    //valid
    let isChecked = true;
    const isEmailCheck = isEmail(email);

    $(".block-signUP")
      .find(".cbx")
      .each((i: number, elem: any) => {
        if (!$(elem).find("input").is(":checked")) {
          isChecked = false;
        }
      });

    if (isEmailCheck && isChecked) {
      $.post(url, { email }).done((data: any) => {
        $(".block-signUP").append(
          `<div class="sendedMail">${check}${data}</div>`
        );
      });
    } else {
      addError(isEmailCheck, isChecked);
    }
  });
};

const isEmail = (email: string) => {
  let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
};

const addError = (isEmailCheck: boolean, isChecked: boolean) => {
  const errorMail = $(".error-mail");
  const errorCheck = $(".error-check");

  if (!isEmailCheck) {
    if (errorMail.length == 0) {
      $(
        '<label class="xss-span error-label error-mail">Wprowadzono błędny adres email</label>'
      ).insertAfter($(".newsletter-btn"));
    }
  } else errorMail.remove();

  if (!isChecked) {
    $(".block-signUP")
      .find(".cbx")
      .each((i: number, elem: any) => {
        let id = $(elem).find("input").attr("id");
        if (!$(elem).find("input").is(":checked")) {
          if ($(`.error-check[del="${id}"]`).length == 0) {
            $(
              `<label class="xss-span error-label error-check" del="${id}">To pole jest obowiązkowe</label>`
            ).insertAfter($(elem));
          }
        } else {
          $(`.error-check[del="${id}"]`).remove();
        }
      });
  } else errorCheck.remove();
};

const check = `<svg width="25" height="25" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.7269 14.0644L16.9775 25.8248L10.8555 19.6918L11.8447 18.7026L16.9775 23.8245L27.7377 13.0752L28.7269 14.0644Z" fill="#D2AC67"/>
<circle cx="19.1621" cy="18.6519" r="17.3262" stroke="#D2AC67" stroke-width="2"/>
</svg>`;
