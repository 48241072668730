import * as $ from 'jquery';

export const init = () => {

    // show list when subelement is active
    const activeMenu = $('.left-container--menu').find('.is-active');
    const isHeader = activeMenu.parent().hasClass('item__header');
    activeMenu.closest('.left-menu__item').removeClass('d-none');
    if(!isHeader) activeMenu.closest('li.left-menu__item').find('.item__arrow').addClass('is-active');

    // click in arrow
    $('.item__arrow').on('click', (e:any)=>{
        const self = $(e.currentTarget);
        let parent = self.closest('.left-menu__item');
        self.toggleClass('is-active');
        parent.find('ul').toggleClass('d-none');
    });
};